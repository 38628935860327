/* You can add global styles to this file, and also import other style files */

@import 'bootstrap/scss/bootstrap';
@import './styles/variables';
@import './styles/fonts';
@import './styles/responsive.scss';
@import 'material-icons/iconfont/material-icons.css';
@import 'assets/plugins/fontawesome-free/css/all.min.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
$title: 'Inter';
$titleRegular: 'Inter Regular';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $titleRegular;
  background-color: #f7f5f5;
}

.pointer {
  cursor: pointer;
}

.btn:active {
  border: transparent;
  border-color: transparent;
}

.btn:focus {
  border: transparent;
  border-color: transparent;
  box-shadow: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.login-card-mobile {
  // padding: 4.3rem 1.5rem !important;
  min-height: 100vh;
  justify-content: center !important;
}

.login-card {
  padding: 2rem 1.5rem;
  border-color: transparent;
  border-radius: 10px;

  // box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  .btn {
    :hover :active :focus {
      border: transparent;
      border-color: transparent;
    }
  }
}

// form-floating start
.form-floating>.form-control:not(:placeholder-shown)~label::after {
  background-color: var(--light-grey);
  content: none;
}

.form-floating>.form-control {
  min-height: 49px;
  height: 46px;
  font-size: 15px;
  background-color: var(--light-grey);
}

.form-floating>label {
  color: var(--dark-grey);
  top: -4px;
}

// form-floating end
.head-title {
  color: #1D2138;
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
}

.head-description {
  color: #4E545C;
  font-weight: 500;
  font-size: 15px;
}

.bottom-description {
  color: #4E545C;
  font-weight: 500;
  font-size: 13px;
}

.bottom-link {
  color: #0162E8;
  font-weight: 700;
  text-decoration: none;
  font-size: 13px;
}

.divider {
  align-items: center;
  display: flex;
  font-variant-ligatures: none;
  color: #4E545C;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  margin: 2rem 0;
}

.divider::before {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--gray-100, #dcdcde);
  margin-right: 5px;
}

.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid var(--gray-100, #dcdcde);
  margin-left: 5px;
}

.btn-hover {
  color: #fff !important;
  height: 48px;
  text-align: center;
  border: none;
  margin-top: 2.5rem;
  background-color: var(--blue-100) !important;

  &:hover,
  &:active {
    color: #fff;
    background-color: var(--blue-100) !important;
  }
}

.badge {
  color: #ecf5ff;
  background-color: #0085ff !important;
  font-size: 10px;
  font-weight: normal;
}

.btn-sec {
  @extend .btn-hover;
  background-color: var(--dark-grey) !important;

  &:hover,
  &:active {
    color: #fff;
    background-color: var(--dark-grey) !important;
  }
}

// Angular material styles
.mat-mdc-form-field {
  display: flex !important;
  border: 1px solid #e4e9f1 !important;
  border-color: transparent !important;
  opacity: 1;
  transition: none;
  // will-change: transform;
  // transform: scale(1);
  // --mat-mdc-form-field-floating-label-scale: 0.89 !important;
}

.mat-mdc-form-field:hover {
  display: flex !important;
  border: 1px solid #e4e9f1 !important;
  border-color: transparent !important;
}

::ng-deep .mdc-notched-outline__leading {
  border: 1px solid #f7f7f7 !important;
}

.mdc-notched-outline {
  border: 1px solid #f7f7f7 !important;
}

::ng-deep .mdc-notched-outline__notch {
  border: 1px solid #f7f7f7 !important;
}

::ng-deep .mdc-notched-outline__trailing {
  border: 1px solid #f7f7f7 !important;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0 !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0) !important;
}

.mat-mdc-text-field-wrapper {
  background: var(--light-grey);
  height: 46px !important;
}

.sample {
  .mat-mdc-text-field-wrapper {
    background: var(--grey-100) !important;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  max-height: 0px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 13px !important;
  padding-bottom: 6px !important;
}

.mat-mdc-form-field-infix {
  min-height: 13px !important;
}

.mdc-text-field .mdc-floating-label {
  top: 55% !important;
}

.mdc-text-field--focused .mdc-floating-label {
  top: 60% !important;
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  content: '' !important;
}

.show-password {
  top: 5px;
  right: 4px;
  cursor: pointer;
  background-color: var(--light-grey);
  box-shadow: none;
  border: none;
  margin: 0px;

  &:hover,
  &:active {
    // background-color: var(--light-grey);
    box-shadow: none;
    border: none;
  }
}

// Angular material styles end

// profile screen style

.profile-title {
  color: #1D2138;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-subTitle {
  @extend .profile-title;
  font-size: 32px;
  font-weight: 600;
  color: #1D2138;
}

.profile-sidemenu {
  @extend .profile-title;
  font-size: 16px;
}

.profile-sub-title {
  @extend .profile-title;
  font-weight: 500;
  font-size: 16px;
}

.type-name {
  @extend .profile-title;
  font-weight: 500;
  font-size: 16px;
  color: var(--blue-100);
}

.apps-name {
  @extend .type-name;
  font-weight: 600;
  color: #4E545C
}

.profile-desc {
  @extend .profile-sub-title;
  font-size: 16px;
  color: #4E545C;
}

.profile-username {
  @extend .profile-title;
  font-size: 28px;
  font-weight: 600;
}

.profile-usermail {
  @extend .profile-title;
  color: #8E9299;
  font-size: 20px;
}

.profile-sub-head {
  @extend .profile-title;
  font-size: 20px;
  font-weight: 500;
}

.profile-top {
  font-size: 16px;
  color: #4E545C;
  font-weight: 400;
}

.profile-bottom {
  font-size: 20px;
  color: #1D2138;
  font-weight: 500;
}

.profile-bottom-des {
  font-size: 14px;
  color: rgba(28, 27, 31, 0.6);
  font-weight: 400;
  color: var(--Sub-Headings, #4E545C);
}

.popup-heading {
  font-size: 15px;
  color: #919ca9;
}

.popup-button {
  font-size: 15px;
  color: #ffffff;
}

.popup-content {
  font-size: 16px;
  color: #1d2138;
}

.no-activity {
  font-size: 16px;
  text-align: center !important;
}

.tick-style {
  width: 180px !important;
  height: 180px !important;
  margin-top: 3rem;
}

// mat-stepper
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 0px 24px !important;
  // margin-bottom: 10px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before,
.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 10px;
}

.pure-checkbox input[type='checkbox']:checked+label:before {
  // .pure-checkbox input[type=checkbox]:hover+label:before {
  background: var(--blue-100) !important;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--blue-100) !important;
}

.mat-horizontal-stepper-header {
  height: 50px !important;
}

.mat-step-label-selected,
.mat-step-label {
  font-size: 18px;
}

.multi-fact-auth-stepper {
  display: flex !important;
  flex-direction: column-reverse !important;

  .mat-horizontal-stepper-header-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    gap: 1rem !important;

    .mat-step-header {
      width: 100%;
      padding: 0 !important;
      height: 10px;
      position: relative;

      &:hover {
        background: transparent;
      }
    }

    .mat-stepper-horizontal-line {
      display: none;
    }
  }

  .mat-step-icon {
    display: none !important;
  }

  //below line is to disable the mat stepper header(step horizantal bars)

  //   .mat-step-header {
  //     pointer-events: none !important;
  // }
  .mat-horizontal-stepper-header::after {
    display: none !important;
  }

  .mat-horizontal-stepper-header::before {
    display: none !important;
  }

  .mat-step-label {
    width: 100% !important;
    height: 1rem !important;
    padding: unset !important;
    background-color: #007bfe73;
    color: rgba(0, 123, 254, 0) !important;
  }

  .mat-step-label-active {
    width: 100% !important;
    padding: unset !important;
    height: 1rem !important;

    background-color: #007bfe73;
    color: rgba(0, 123, 254, 0) !important;
  }

  .mat-step-label-active.mat-step-label-selected {
    background-color: var(--blue-100);
    height: 1rem !important;
    color: rgba(0, 123, 254, 0) !important;
  }

  .mat-horizontal-content-container {
    height: 25rem;
  }
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header .mat-step-icon {
  width: 100% !important;
  height: 1rem !important;
  border-radius: unset !important;
  background-color: #ffffff !important;
}

.mat-step-icon {
  height: 32px !important;
  width: 32px !important;
}

.mat-step-icon .mat-mail {
  height: 23px !important;
  font-size: 15px !important;
}
.mat-step-icon .mat-whatsapp {
  height: 25px !important;
  width: 25px !important;
  font-size: 15px !important;
}

.mat-step-icon .mat-otp {
  width: 20px !important;
  height: 23px !important;
  font-size: 15px !important;
}

.text-danger {
  color: red !important;
}

.activity-th>th {
  color: #1D2138;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom-width: 0px;
  padding-top: 24px;

  &:first-child {
    padding-left: 2rem;
  }
}

.activity-td>td {
  color: #1D2138;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom-width: 0px;
  padding-top: 24px !important;

  &:first-child {
    padding-left: 2rem !important;
  }
}

.reg-field-title {
  color: var(--Title, #09213f);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.reg-field-data {
  @extend .reg-field-title;
  color: rgba(0, 0, 0, 0.83);
}

.placeholder {
  font-weight: 500;
  font-size: 15px !important;
}

.mdc-text-field__input {
  height: 28px !important;
}

.bg-content {
  background: #fff;
}

.page-not-found-head {
  @extend .profile-title;
  font-size: 32px;
  color: var(--blue-200);
}

.page-not-found-desc {
  color: var(--dark-grey);
  text-align: center;
  font-size: 16.113px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border: transparent !important;
}

#dial-code {
  padding-top: 6px !important;
  margin-right: 5px !important;
}

.logo-url {
  max-width: 10rem;
  width: 10rem;
  height: auto;
}

.mat-drawer-container {
  background-color: #fff;
}

.mat-mdc-raised-button .mat-mdc-button-ripple {
  background-color: #fff !important;
}

.circle {
  border-radius: 50%;
  width: 66px;
  height: 66px;
  padding: 10px;
  background: #fff;
  border: 1px solid #49494c;
  color: var(--darkBlue);
  text-align: center;
  font-size: 32px;
  margin: 0 auto;
  font-weight: 700;
}

.sticky-top {
  z-index: 940 !important;
}

// border color of input field
.mdc-text-field--outlined .mdc-notched-outline__leading,
.mdc-text-field--outlined .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline__trailing {
  border-color: #CECFD4 !important;
}

.mdc-text-field .mdc-floating-label {
  color: #919CA9 !important;
  background-color: #FAFBFE;
  font-size: 15px;
  font-weight: 500;
  border-color: #CECFD4 !important;
}

.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 15px !important;
}

.toast-container {
  position: fixed !important;
}

.mfa-step {
  .mat-stepper-horizontal-line {
    min-width: 100px !important;
  }

  .mat-step-label {
    min-width: 0px !important;
  }
}

/*****************************************************
	Custom styling for Country Code Dropdown
*****************************************************/

.iti {
  display: block !important;
  // margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 14px;
  margin-left: 0;
  width: 244px;
  max-height: 170px;
}

.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti__flag-container.open>.dropdown-menu.show {
  inset: 100% auto auto 0px !important;
}

.iti input[type=tel]::placeholder {
  color: #919CA9 !important;
}

.iti.separate-dial-code {
  background-color: #fafbfe;
  display: flex !important;
}

.iti__selected-flag {
  border: 0.5px solid rgb(137 143 150 / 25%);
  width: auto !important;
  height: 69% !important;
  cursor: pointer !important;
  border-radius: 5px 0 0 5px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.iti.separate-dial-code input {
  border-left: none !important;
  border-radius: 0 5px 5px 0;
}

.iti .search-container input {
  font-size: 14px;
  border-color: #c7cace;
  border-radius: 0;
  padding: 5px 10px;
}

.iti .search-container input:focus {
  outline: none;
}

@media screen and (max-width: 479px) {
  .iti .iti__country-list {
    width: 88.3vw;
  }
}

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding-left: 10px !important;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}