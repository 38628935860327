// You can add global styles to this file, and also inport other style files
// font faces - Inter fonts
@font-face{
  font-family: 'Inter Black';
  src: url("/assets/fonts/Inter/Inter-Black.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter Bold';
  src: url("/assets/fonts/Inter/Inter-Bold.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter ExtraBold';
  src: url("/assets/fonts/Inter/Inter-ExtraBold.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter ExtraLight';
  src: url("/assets/fonts/Inter/Inter-ExtraLight.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter Light';
  src: url("/assets/fonts/Inter/Inter-Light.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter Medium';
  src: url("/assets/fonts/Inter/Inter-Medium.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter Regular';
  src: url("/assets/fonts/Inter/Inter-Regular.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter SemiBold';
  src: url("/assets/fonts/Inter/Inter-SemiBold.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Inter Thin';
  src: url("/assets/fonts/Inter/Inter-Thin.ttf") format("woff");
  font-display: swap;
}
// font faces - Gilroy fonts
@font-face {
  font-family: 'Gilroy Black';
  src: url("/assets/fonts/Gilroy/Gilroy-Black.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy BlackItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-BlackItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy Bold';
  src: url("/assets/fonts/Gilroy/Gilroy-Bold.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy BoldItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-BoldItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy ExtraBold';
  src: url("/assets/fonts/Gilroy/Gilroy-ExtraBold.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy ExtraBoldItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-ExtraBoldItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy Heavy';
  src: url("/assets/fonts/Gilroy/Gilroy-Heavy.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy HeavyItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-HeavyItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy Light';
  src: url("/assets/fonts/Gilroy/Gilroy-Light.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy LightItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-LightItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy Medium';
  src: url("/assets/fonts/Gilroy/Gilroy-Medium.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy MediumItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-MediumItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy Regular';
  src: url("/assets/fonts/Gilroy/Gilroy-Regular.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy RegularItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-RegularItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy SemiBold';
  src: url("/assets/fonts/Gilroy/Gilroy-SemiBold.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy SemiBoldItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-SemiBoldItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy Thin';
  src: url("/assets/fonts/Gilroy/Gilroy-Thin.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy ThinItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-ThinItalic.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy UltraLight';
  src: url("/assets/fonts/Gilroy/Gilroy-UltraLight.ttf") format("woff");
  font-display: swap;
}
@font-face{
  font-family: 'Gilroy UltraLightItalic';
  src: url("/assets/fonts/Gilroy/Gilroy-UltraLightItalic.ttf") format("woff");
  font-display: swap;
}